body {
  margin: 0px;
  padding: 0px;
}
.App {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
  height: 100vh;
}

#contract {
  height: 100vh;
  overflow-x: hidden !important;
  width: 100vw;
  overflow-y: hidden !important;
}
#adobe-dc-view {
  width: 100%;
  height: 100%;
}
#plans {
  width: 100%;
  height: 100vh;
}
input.zc-live-primary-btn {
  background: green !important;
  padding: 10px !important;
  width: 100px !important;
  height: 40px !important;
  border-radius: 10px !important;
  border: 4px solid #ffd17e !important;
}
#contractDesktop {
  width: 100%;
  height: 100vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 376px;
}
.text-center {
  /* font-family: "Helvetica",sans-serif; */
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 400;
  margin-bottom: 70px;
  margin-top: 60px;
}
#qrCode_Subbies {
  height: 300px;
}
